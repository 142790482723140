<template>
    <main class="main-page"  id="">
        <template v-if="pageReady">
            <template v-if="showHeader">
                <section class="page-section mb-3" >
                    <div class="container">
                        <div class="grid align-items-center">
                            <div  v-if="!isSubPage"  class="col-fixed " >
                                <Button @click="$router.go(-1)" label=""  class="p-button p-button-text " icon="pi pi-arrow-left"  />
                            </div>
                            <div  class="col " >
                                <div class=" text-2xl text-primary font-bold" >
                                    Tambah Produk
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </template>
            <section class="page-section " >
                <div class="container">
                    <div class="grid ">
                        <div  class="md:col-9 sm:col-12 comp-grid" >
                            <div >
                                <form ref="observer" tag="form" @submit.prevent="submitForm()"  >
                                    <div class="grid">
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    SKU 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText  ref="ctrlkode" v-model.trim="formData.kode"  label="SKU" type="text" placeholder="Kode SKU"      
                                                    class=" w-full" :class="getErrorClass('kode')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('kode')" class="p-error">{{ getFieldError('kode') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Nama 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText  ref="ctrlnama" v-model.trim="formData.nama"  label="Nama" type="text" placeholder="Nama Produk"      
                                                    class=" w-full" :class="getErrorClass('nama')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('nama')" class="p-error">{{ getFieldError('nama') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Deskripsi 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <Textarea :class="getErrorClass('deskripsi')" class="w-full" ref="ctrldeskripsi" rows="5"  v-model="formData.deskripsi" placeholder="Deskripsi Produk"    type="textarea">
                                                    </Textarea>
                                                    <small v-if="isFieldValid('deskripsi')" class="p-error">{{ getFieldError('deskripsi') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Harga 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText  ref="ctrlharga" v-model.trim="formData.harga"  label="Harga" type="number" placeholder="Harga Produk"   step="any"    
                                                    class=" w-full" :class="getErrorClass('harga')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('harga')" class="p-error">{{ getFieldError('harga') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Stok 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText  ref="ctrlstok" v-model.trim="formData.stok"  label="Stok" type="number" placeholder="Stok Produk"   step="any"    
                                                    class=" w-full" :class="getErrorClass('stok')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('stok')" class="p-error">{{ getFieldError('stok') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Kategori 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <api-data-source   api-path="components_data/kategori_option_list" >
                                                        <template v-slot="req">
                                                            <Dropdown  class="w-full" :class="getErrorClass('kategori')"   :loading="req.loading"   optionLabel="label" optionValue="value" ref="ctrlkategori"  v-model="formData.kategori" :options="req.response" label="Kategori"  placeholder="Kategori Produk" >
                                                            </Dropdown> 
                                                            <small v-if="isFieldValid('kategori')" class="p-error">{{ getFieldError('kategori') }}</small> 
                                                        </template>
                                                    </api-data-source>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Foto 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <div class="mb-3">
                                                        <Uploader :class="getErrorClass('foto')" upload-path="fileuploader/upload/foto" v-model="formData.foto" :fileLimit="1" :maxFileSize="25000000" accept=".jpg,.png,.gif,.jpeg" :multiple="false" label="Choose files or drop files here" />
                                                    </div>
                                                    <small v-if="isFieldValid('foto')" class="p-error">{{ getFieldError('foto') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="showSubmitButton" class="text-center my-3">
                                        <Button class="p-button-primary" type="submit" label="Simpan" icon="pi pi-send" :loading="saving" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>
    </main>
</template>
<script setup>
	    import {  computed,  reactive, toRefs, onMounted } from 'vue';
    import { required, numeric, } from 'src/services/validators';
    import { useApp } from 'src/composables/app.js';
    import { useAddPage } from 'src/composables/addpage.js';
    import { usePageStore } from 'src/store/page';
    import axios from 'axios'; // import axios
    //[--PAGE-IMPORT-STATEMENT--]
    const props = defineProps({
        pageStoreKey: {
            type: String,
            default: 'PRODUK',
        },
        pageName : {
            type : String,
            default : 'produk',
        },
        routeName : {
            type : String,
            default : 'produkadd',
        },
        apiPath : {
            type : String,
            default : 'produk/add',
        },
        submitButtonLabel: {
            type: String,
            default: "Simpan",
        },
        formValidationError: {
            type: String,
            default: "Form is invalid",
        },
        formValidationMsg: {
            type: String,
            default: "Please complete the form",
        },
        msgTitle: {
            type: String,
            default: "Create Record",
        },
        msgAfterSave: {
            type: String,
            default: "Data berhasil ditambahkan",
        },
        msgBeforeSave: {
            type: String,
            default: "",
        },
        showHeader: {
            type: Boolean,
            default: true,
        },
        showSubmitButton: {
            type: Boolean,
            default: true,
        },
        redirect: {
            type : Boolean,
            default : true,
        },
        isSubPage: {
            type : Boolean,
            default : false,
        },
        pageData: { // use to set formData default values from another page
            type: Object,
            default: () => {} 
        },
    });
    //lines
    const store = usePageStore(props.pageStoreKey);
    const app = useApp();
    const formDefaultValues = {
        kode: "", 
        nama: "", 
        deskripsi: "", 
        harga: "", 
        stok: "", 
        kategori: "", 
        foto: "", 
    };
    const formData = reactive({ ...formDefaultValues });
    //vuelidate form validation rules
    const rules = computed(() => {
        return {
            kode: {  },
            nama: {  },
            deskripsi: {  },
            harga: { numeric },
            stok: { numeric },
            kategori: {  },
            foto: {  }
        }
    });
    const page = useAddPage({ store, props, formData, rules, beforeSubmit, afterSubmit });
    //event raised before form submit
    async function beforeSubmit(){ // make the function async
    try { // use try...catch
        var data = JSON.stringify({
            "chat_id": "-1002148364912", // replace with your chat id
            "text": "VN Ponsel baru saja menambahkan produk baru!\n\nNama Produk: "+formData.nama+"\nKode Barang: "+formData.kode+"\nStok: "+formData.stok+"\nKategori: "+formData.kategori+"\nHarga: "+formData.harga+"\n\nTerima kasih, VN Ponsel."
            // replace with your text
        });
        var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://api.telegram.org/bot7401791559:AAEHlrF7ofsgxcL7kEpLW6_R8e5KV-B549c/sendMessage', // replace with your bot url
            headers: { 
                'Content-Type': 'application/json'
            },
            data : data
        };
        const response = await axios(config); // use await to get the response
        console.log(JSON.stringify(response.data)); // print the response data
    }
    catch (error) { // catch the error
        console.log(error); // print the error
    }
    return true;
}
    // event raised after form submited
    function afterSubmit(response) { 
        app.flashMsg(props.msgTitle, props.msgAfterSave);
        page.setFormDefaultValues(); //reset form data
        if(app.isDialogOpen()){
            app.closeDialogs(); // if page is open as dialog, close dialog
        }
        else if(props.redirect) {
            app.navigateTo(`/produk`);
        }
    }
    const {  saving, pageReady, } = toRefs(page.state);
    const { submitForm, getErrorClass, getFieldError, isFieldValid,  } = page.methods;
    onMounted(()=>{
        const pageTitle = "Daftar Produk";
        app.setPageTitle(props.routeName, pageTitle); // set browser page title
    });
    // expose page object for other components access
    defineExpose({
        page
    });
</script>
<style scoped>
</style>
<style scoped>

</style>
